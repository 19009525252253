.header-sticky {
  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
    z-index: 999;
    transition: $transition-base;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, .1);

    .header-top-bar {
      display: none !important;
    }

    .mobile-navigation-icon {
      display: block !important;
    }

    .header-navigation {
      display: none !important;
    }
  }
}

.header-top-bar {
  color: rgba(255, 255, 255, 0.6);

  ul {
    li {
      &:not(:first-child) {
        margin-left: 30px;
      }
    }

    a {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  svg {
    margin-right: 8px;
  }

  .btn {
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 0;
    line-height: 2;
  }
}

.header-info-area {
  padding: 15px 0;
  background-color: $white;
}

.header-navigation {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  > ul {
    display: flex;

    > li {
      transition: $transition-base;

      > a {
        display: flex;
        align-items: center;
        padding: 18px 15px;
        font-weight: 500;
        color: $gray-900;

        &:hover,
        &.active {
          color: $primary;
        }

        svg {
          margin-left: 8px;
        }
      }

      &.has-children {
        position: relative;

        &:hover {
          > .submenu {
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 10px 37px rgba(0, 0, 0, .07);
  border-bottom: 3px solid $secondary;
  background-color: $white;
  transform: translateY(50px);
  transition: $transition-base;
  transition-delay: 0.2s;
  transition-duration: 0.3s;
  visibility: hidden;
  opacity: 0;
  min-width: 270px;
  padding: 25px 0;
  z-index: 9;

  li {
    position: relative;

    > a {
      display: block;
      padding: 11px 30px;
      color: $gray-600;

      &:hover, &.active {
        color: $primary;
      }
    }
  }
}

.mobile-navigation-icon {
  width: 24px;
  height: 25px;
  position: relative;
  cursor: pointer;
  float: right;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &:hover {
    i {
      background-color: $gray-900;
      &:before {
        width: 80%;
        background-color: $gray-900;
      }
      &:after {
        background-color: $gray-900;
        width: 60%;
      }
    }
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: rgba(34, 34, 34, 0.4);
    transition: $transition-base;

    &:before {
      position: absolute;
      bottom: 8px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: rgba(34, 34, 34, 0.4);
      content: "";
      transition: $transition-base;
    }

    &:after {
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: rgba(34, 34, 34, 0.4);
      content: "";
      transition: $transition-base;
    }
  }
}

.offcanvas-mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  max-width: 100%;
  height: 100vh;
  z-index: 9999;
  transform: translateX(100%);
  padding-left: 60px;
  transition: 0.6s;

  &.active {
    transform: translateX(0);
  }
}

.offcanvas-menu-close {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  border: 0;
  background: $gray-800;
  z-index: 9;
  width: 60px;
  height: 60px;
  color: $white;
  line-height: 60px;
  text-align: center;
  font-size: 25px;

  &:focus {
    outline: none;
  }
}

.offcanvas-wrapper {
  overflow: auto;
  height: 100%;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: $white;
}

.offcanvas-inner-content {
  padding: 35px;
}

.off-canvas-contact {
  li {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    a {
      color: $gray-900;

      &:hover {
        color: $secondary;
      }
    }
  }
}

.offcanvas-navigation {
  margin-bottom: 50px;

  ul {
    &.sub-menu {
      margin-left: 25px;

      > li {
        > a {
          font-size: 13px;
        }
      }
    }

    li {
      &.menu-item-has-children {
        position: relative;

        .sub-menu-enter {
          max-height: 0;
          opacity: 0;
          transition: all .3s ease-in;
        }

        .sub-menu-enter.sub-menu-enter-active {
          opacity: 1;
          max-height: 1000px;
        }

        .sub-menu-leave {
          opacity: 1;
          max-height: 1000px;
          transition: all .3s ease-out;
        }

        .sub-menu-leave.sub-menu-leave-active {
          max-height: 0;
          opacity: 0;
        }

        &.active {
          & > .menu-expand {
            i {
              &:before {
                transform: rotate(0);
              }
            }
          }
        }

        .menu-expand {
          position: absolute;
          right: auto;
          left: 95%;
          top: -5px;
          width: 30px;
          height: 50px;
          line-height: 50px;
          cursor: pointer;
          text-align: center;

          i {
            display: block;
            margin-top: 25px;
            border-bottom: 1px solid;
            position: relative;
            width: 10px;
            transition: all 250ms ease-out;
            &:before {
              width: 100%;
              content: "";
              border-bottom: 1px solid;
              display: block;
              position: absolute;
              top: 0;
              transform: rotate(90deg);
            }
          }
        }
      }

      a {
        display: block;
        color: $gray-900;
        line-height: 20px;
        padding: 10px 0;

        &:hover {
          color: $secondary;
        }
      }
    }
  }

  > ul {
    > li {
      > a {
        font-weight: 500;
      }
    }
  }
}

.logo {
  img {
    max-width: 160px;
  }
}
