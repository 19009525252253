@for $i from 0 through 15 {
  .row-#{1 * $i} {
    margin-left: -1px * $i;
    margin-right: -1px * $i;

    & > [class*="col"] {
      padding-left: 1px * $i;
      padding-right: 1px * $i;
    }
  }
}

@for $i from 16 through 80 {
  .row-#{1 * $i} {
    margin-left: -15px;
    margin-right: -15px;

    @include media-breakpoint-up(xl) {
      margin-left: -1px * $i;
      margin-right: -1px * $i;
    }

    & > [class*="col"] {
      padding-left: 15px;
      padding-right: 15px;

      @include media-breakpoint-up(xl) {
        padding-left: 1px * $i;
        padding-right: 1px * $i;
      }
    }
  }
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;

  & > .col, & > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
    margin: 0;
  }
}
