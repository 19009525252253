.project-item-wrapper {
  margin-bottom: -30px;
}

.project-details {
  h2 {
    font-weight: 600;
    margin-bottom: 20px;
  }

  p {
    line-height: 30px;
    color: $gray-700;
  }
}

.project-information {
  padding: 35px;
  border: 1px solid #e4e8ed;
  box-shadow: 0 5px 30px rgba(105, 106, 119, 0.05);
  background-color: $white;

  h6 {
    color: $secondary;
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 18px;
  }

  ul {
    li {
      display: flex;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        flex: 1;
        color: $gray-700;

        &:last-child {
          font-weight: 500;
          color: $gray-900;
        }
      }
    }
  }
}

.gallery-item {
  position: relative;
  background: none;
  border: none;
  padding: 0;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: $black--900;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease 0s;
  }

  img {
    width: 100%;
  }

  .plus {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 3;
    opacity: 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: $white;
      transition: all 0.3s ease 0s;
    }

    &::before {
      width: 150px;
      height: 1px;
    }

    &::after {
      width: 1px;
      height: 150px;
    }
  }

  &:hover {
    &::before {
      opacity: 0.75;
    }

    .plus {
      opacity: 1;

      &::before {
        width: 40px;
      }

      &::after {
        height: 40px;
      }
    }
  }
}
