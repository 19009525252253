.services-sidebar {
  display: flex;
  flex-flow: column wrap;

  .services-tab-list {
    order: 1;

    @include media-breakpoint-up(lg) {
      order: 0;
    }
  }
  .cta-widget {
    order: 0;

    @include media-breakpoint-up(lg) {
      order: 1;
    }
  }
}

.service-item-wrapper {
  margin-bottom: -30px;
}

.services-tab {
  padding: 20px 15px;
  color: $gray-900;
  background: rgba(65, 58, 164, 0.05);
  margin-bottom: 5px;
  transition: $transition-base;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &:hover,
  &-active {
    background: $secondary;
    color: $white;
  }
}

.service-full {
  .image {
    position: relative;
    margin-bottom: 90px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    background-color: $secondary;
    position: absolute;
    bottom: -35px;
    left: 20px;

    svg {
      color: $white;
      font-size: 30px;
    }
  }

  .title {
    margin-bottom: 30px;
  }

  .subtitle,
  .description {
    line-height: 30px;
  }

  .description {
    color: $gray-700;
  }
}
