.error-page-content-wrap {
  font-size: 16px;
  color: $gray-700;
  line-height: 30px;

  h1 {
    color: $gray-900;
    font-size: 80px;
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    line-height: 1;
    font-weight: 900;

    @include media-breakpoint-up(md) {
      font-size: 100px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 120px;
    }
  }

  h2 {
    font-size: 20px;
    color: $black;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      font-size: 32px;
      margin-bottom: 20px;
    }
  }

  .btn {
    padding: 10px 30px;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 2;
  }
}
