.section-title {
  display: inline-block;
  font-size: 30px;
  color: $secondary;
  font-weight: 700;

  @include media-breakpoint-up(sm) {
    font-size: 40px;
  }
}
