.footer-content-wrapper {
  color: rgba($white, 0.5);
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: $black--900;
}

.footer-logo {
  margin-bottom: 30px;

  img {
    max-width: 160px;
  }
}

.footer-widget {
  margin-bottom: 30px;
}

.footer-widget-title {
  color: $white;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.footer-widget-navigation {
  li {
    &:not(:last-child) {
      margin-bottom: 20px
    }
  }

  a {
    color: rgba($white, 0.5);

    &:hover {
      color: $white;
    }
  }
}

.footer-widget-about {
  margin-bottom: 20px;

  p {
    line-height: 30px;
  }

  a {
    color: $white;
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }
  }
}

.footer-widget-contact {
  p {
    margin-bottom: 20px;
  }

  a {
    color: rgba($white, 0.5);

    &:hover {
      color: $white;
    }
  }
}

.footer-copyright-wrapper {
  padding: 30px 0;
  background-color: $black;
  color: rgba($white, 0.6);

  a {
    color: $secondary;
  }
}
