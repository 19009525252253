.ht-swiper-button-nav {
  background-color: rgba(255, 255, 255, 0.3);
  border: 0;
  color: $white;

  &:hover {
    background-color: $secondary;
    border-color: $secondary;
  }
}

.ht-swiper-button-prev {
  left: 100px;

  @include media-breakpoint-up(lg) {
    left: 20px;
  }
}

.ht-swiper-button-next {
  right: 100px;

  @include media-breakpoint-up(lg) {
    right: 20px;
  }
}

.hero-slider-item {
  width: 100vw !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: rgba($primary, 0.7);
    z-index: 1;
  }
}

.hero-slider-content-wrapper {
  position: relative;
  z-index: 9;
}

.hero-slider-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 450px;
  width: 580px;
  max-width: 100%;

  @include media-breakpoint-up(md) {
    height: 550px;
  }

  @include media-breakpoint-up(lg) {
    height: 600px;
  }

  @include media-breakpoint-up(xl) {
    height: 730px;
  }

  & > * {
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
}

.swiper-slide-active {
  .hero-slider-content {
    & > * {
      &:nth-child(1) {
        animation-name: fadeInDown;
        animation-duration: 1.5s;
        animation-delay: 0.5s;
      }

      &:nth-child(2) {
        animation-name: fadeInLeft;
        animation-duration: 1.5s;
        animation-delay: 1s;
      }
      &:nth-child(3) {
        animation-name: fadeInDown;
        animation-duration: 1.5s;
        animation-delay: 2.5s;
      }
    }
  }
}

.hero-slider-title {
  font-size: 35px;
  line-height: 1.2;
  color: $white;
  margin-bottom: 30px;
  margin-top: -15px;

  @include media-breakpoint-up(md) {
    font-size: 40px;
    margin-top: -8px;
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 60px;
    margin-top: -12px;
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 70px;
    margin-top: -15px;
  }
}

.hero-slider-text {
  font-size: 16px;
  line-height: 1.8;
  color: $white;
  margin-bottom: 40px;

  @include media-breakpoint-up(md) {
    margin-bottom: 30px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 50px;
  }
}

.hero-slider-btn {
  font-size: 13px;
  line-height: 1;
  padding: 20px 60px;
  align-self: flex-start;
  border-radius: 50px;
  transition: $transition-base;
  text-transform: uppercase;
}
