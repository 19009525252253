.about-section {
  .image {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      margin-bottom: 50px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .content {
    h2 {
      font-weight: 600;
      margin-bottom: 15px;
      font-size: 24px;

      @include media-breakpoint-up(sm) {
        font-size: 30px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 36px;
      }
    }

    h3 {
      font-size: 22px;
    }

    h4 {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 25px;
    }

    p {
      margin-bottom: 20px;
      line-height: 30px;
      color: $gray-700;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn {
      padding: 10px 30px;
      border-radius: 0;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 2;
    }
  }
}

.feature-section {
  background-color: #f7f7f7;
  padding-bottom: 10px;

  @include media-breakpoint-up(md) {
    padding-bottom: 30px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 50px;
  }

  .loading {
    display: block;
    margin-bottom: 50px;
  }
}

.feature {
  display: flex;

  .icon {
    color: $secondary;
    min-width: 50px;
    margin-right: 20px;
    font-size: 36px;
  }

  .content {
    h3 {
      font-size: 24px;
      font-weight: 500;

      a {
        color: $gray-900;
      }
    }

    p {
      color: $gray-700;
      line-height: 30px;
    }
  }
}

.about-section-2 {
  .btn {
    padding: 10px 30px;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 2;
  }

  .about-wrapper {
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;

    & > .col, & > [class*="col-"] {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 0;

      & > .col, & > [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.about-image {
  position: relative;
  z-index: 1;

  &.about-image-1 {
    @include media-breakpoint-up(lg) {
      margin-bottom: -50px;
    }
  }

  &.about-image-2 {
    margin-top: 30px;

    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -50px;
    }
  }

  img {
    width: 100%;
  }
}

.about-content {
  &.about-content-1 {
    margin-top: 30px;
    margin-bottom: 0;
    padding-right: 0;

    @include media-breakpoint-up(md) {
      margin-top: 50px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-bottom: 50px;
      padding-right: 70px;
    }
  }

  &.about-content-2 {
    margin-top: 15px;
    padding-left: 0;

    @include media-breakpoint-up(lg) {
      margin-top: 50px;
      padding-left: 70px;
    }
  }

  h3 {
    font-weight: 400;
    position: relative;
    margin-bottom: 20px;
    font-size: 24px;
    display: flex;
    align-items: flex-end;

    @include media-breakpoint-up(sm) {
      font-size: 30px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 36px;
      margin-bottom: 30px;
    }
  }

  p {
    margin-bottom: 20px;
    color: $gray-700;
    line-height: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
