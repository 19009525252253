.scroll-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, .03);
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 25px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: $primary;
  color: $white;
  transition: all .5s cubic-bezier(.645, .045, .355, 1);
  border-color: transparent;

  &:focus {
    outline: none;
  }

  &.show {
    visibility: visible;
    opacity: 1;
    bottom: 60px;
  }
}
