.cta-widget {
  border: 1px solid #e4e8ed;

  h6 {
    padding: 15px;
    font-weight: 500;
    text-align: center;
    border-bottom: 1px solid #e4e8ed;
    margin: 0;
  }

  .content {
    color: $gray-600;
    text-align: center;
    padding: 20px 30px 30px;

    p {
      margin: 0;
      line-height: 30px;

      &.call-us {
        svg {
          color: $secondary;
          margin-right: 8px;
        }

        a {
          font-weight: 500;
          color: $gray-900;
        }
      }

      &.message-us {
        a {
          line-height: 32px;
          padding: 4px 15px;

          svg {
            margin-right: 5px;
          }
        }
      }

      > span {
        display: block;
      }
    }
  }
}
