.page-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -5px -10px;

  li {
    font-size: 16px;
    line-height: 24px;
    color: $black--900;
    text-align: center;
    margin: 5px 10px;

    a {
      color: $black--900;
      background-color: #f8f8f8;
      padding: 10px;
      border-radius: 50px;
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        line-height: 24px;
      }
    }

    &:hover {
      a {
        color: $white;
        background-color: $black--900;
      }
    }

    &.active {
      a {
        color: $white;
        background-color: $primary;
      }
    }

    &:first-child {
      a {
        color: $black--900;
        width: auto;
        padding: 10px 20px;

        i {
          margin-right: 10px;
          float: left;
        }

        &:hover {
          color: $white;
        }
      }
    }

    &:last-child {
      a {
        color: $black--900;
        width: auto;
        padding: 10px 20px;
        flex-direction: row-reverse;

        i {
          margin-left: 10px;
          float: right;
        }

        &:hover {
          color: $white;
        }
      }
    }
  }
}
