.contact-map {
  iframe {
    height: 400px;
    width: 100%;
    border: none;
  }
}

.contact-information {
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 30px;
  }

  ul {
    li {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        width: 50px;

        svg {
          display: block;
          font-size: 24px;
          color: $secondary;
        }
      }

      .text {
        max-width: calc(100% - 50px);
        span, a {
          display: block;
          line-height: 24px;
          max-width: 230px;
          color: $gray-900;

          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }
}

.contact-form {
  h3 {
    margin-bottom: 30px;
  }

  input, textarea {
    width: 100%;
    border: 1px solid #eeeeee;
  }

  input {
    height: 50px;
    padding: 5px 20px;
  }

  textarea {
    height: 120px;
    padding: 10px 20px;
    resize: none;
  }

  input[type="submit"], button, .submit {
    width: auto;
    height: 50px;
    border: none;
    padding: 5px 30px;
    font-weight: 700;
    margin-top: 15px;
  }
}
