.breadcrumb-area {
  padding: 110px 0 30px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 1;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba($black, 0.6);
    z-index: -1;
  }
}

.breadcrumb-bg {
  background-image: url('../../images/contact.jpg');
}

.page-banner {
  h1 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 90px;
    line-height: 48px;
    color: $white;
  }
}

.page-breadcrumb {
  display: flex;
  justify-content: center;

  li {
    color: rgba($white, 0.5);

    &:not(:last-child) {
      &::after {
        content: "-";
        margin: 0 8px;
      }
    }

    a {
      color: $white;

      &:hover {
        color: $secondary;
      }
    }
  }
}
