.video-cta-area {
  background-color: #f7f7f7;
}

.video-cta-content {
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  &__small-title {
    margin-top: -5px;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.5;
    color: $secondary;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  &__title {
    font-size: 30px;
    line-height: 1.2;
    color: $gray-900;
    max-width: 100%;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      font-size: 36px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 30px;
    color: $gray-700;
    margin-bottom: 20px;
    width: 500px;
    max-width: 100%;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }

  .btn {
    border-radius: 100px;
    padding: 15px 50px;
    text-transform: uppercase;
    font-size: 13px;
  }
}

.cta-video-image {
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;

  a {
    display: block;
  }

  &__image {
    overflow: hidden;

    img {
      transition: $transition-base;
      transition-duration: 0.6s;
      width: 100%;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $secondary;
    position: absolute;
    top: 50%;
    transition: $transition-base;
    transition-duration: 0.6s;
    width: 70px;
    height: 70px;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media-breakpoint-up(lg) {
      width: 100px;
      height: 100px;
      left: -50px;
      transform: translateY(-50%) scale(1);
    }

    svg {
      font-size: 24px;
      color: $white;
    }
  }

  &:hover {
    .cta-video-image__image {
      img {
        transform: scale(1.1);
      }
    }

    .cta-video-image__icon {
      transform: translate(-50%, -50%) scale(1.1);

      @include media-breakpoint-up(lg) {
        transform: translateY(-50%) scale(1.1);
      }
    }
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-right: 5px solid $secondary;
    border-bottom: 5px solid $secondary;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: -1;
  }

  .video-popup {
    & > button {
      background: none;
      border: none;
      width: 100%;
    }
  }
}
