@import "base/variables";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/animate.css/animate.min.css";
@import "../../node_modules/lightgallery.js/dist/css/lightgallery.css";
@import "../../node_modules/react-modal-video/scss/modal-video.scss";

@import "base/mixins";
@import "base/helper";
@import "global/default";
@import "global/spacing";
@import "layout/header";
@import "layout/footer";
//@import 'layout/sidebar';
@import "elements/button";
@import "elements/section-title";
@import "elements/scroll-top";
@import "elements/breadcrumb";
@import "elements/pagination";
@import "elements/preloader";
@import "elements/cta";
@import "sections/hero-slider";
@import "sections/service-grid-slider";
@import "sections/video-cta";
@import "sections/testimonial-slider";
//@import 'pages/blog';
@import "pages/about";
@import "pages/service";
@import "pages/project";
@import "pages/contact";
@import "pages/404";
