.testimonials-slider-area {
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.2rem;

  .ht-swiper-button-nav:hover {
    background-color: $white;
    border-color: $white;
    color: $gray-900;
  }
}
.testimonials-slider-item {
  .image {
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
    border-radius: 50% !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;

    img {
      width: 70%;
    }
  }

  .author-spec {
    margin-bottom: 50px;

    strong {
      font-weight: 500;
      color: $white;
    }
  }

  .author-text {
    width: 50%;
    margin: 0 auto;
    position: relative;
    line-height: 34px;
    color: rgba(255, 255, 255, 0.7);

    svg {
      position: absolute;
      font-size: 120px;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: -50px;
      color: rgba(255, 255, 255, 0.05);
    }
  }
}