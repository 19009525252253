.section-space {
  &--inner {
    &--50 {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &--60 {
      padding-top: 40px;
      padding-bottom: 40px;

      @include media-breakpoint-up(lg) {
        padding-top: 60px;
        padding-bottom: 60px;
      }
    }

    &--80 {
      padding-top: 40px;
      padding-bottom: 40px;

      @include media-breakpoint-up(md) {
        padding-top: 60px;
        padding-bottom: 60px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 80px;
        padding-bottom: 80px;
      }
    }

    &--100 {
      padding-top: 60px;
      padding-bottom: 60px;

      @include media-breakpoint-up(md) {
        padding-top: 80px;
        padding-bottom: 80px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 100px;
        padding-bottom: 100px;
      }
    }

    &--120 {
      padding-top: 60px;
      padding-bottom: 60px;

      @include media-breakpoint-up(md) {
        padding-top: 80px;
        padding-bottom: 80px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 120px;
        padding-bottom: 120px;
      }
    }

    &--140 {
      padding-top: 80px;
      padding-bottom: 80px;

      @include media-breakpoint-up(md) {
        padding-top: 100px;
        padding-bottom: 100px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 140px;
        padding-bottom: 140px;
      }
    }

    &--190 {
      padding-top: 100px;
      padding-bottom: 100px;

      @include media-breakpoint-up(md) {
        padding-top: 120px;
        padding-bottom: 120px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 190px;
        padding-bottom: 190px;
      }
    }

    &--top {
      &--30 {
        padding-top: 30px;
      }

      &--120 {
        padding-top: 60px;

        @include media-breakpoint-up(md) {
          padding-top: 80px;
        }

        @include media-breakpoint-up(lg) {
          padding-top: 120px;
        }
      }

      &--200 {
        padding-top: 60px;

        @include media-breakpoint-up(md) {
          padding-top: 80px;
        }

        @include media-breakpoint-up(lg) {
          padding-top: 200px;
        }
      }
    }

    &--bottom {
      &--30 {
        padding-bottom: 30px;
      }

      &--50 {
        padding-bottom: 50px;
      }

      &--120 {
        padding-bottom: 60px;

        @include media-breakpoint-up(md) {
          padding-bottom: 80px;
        }

        @include media-breakpoint-up(lg) {
          padding-bottom: 120px;
        }
      }

      &--285 {
        padding-bottom: 265px;

        @include media-breakpoint-up(md) {
          padding-bottom: 285px;
        }
      }

      &--300 {
        padding-bottom: 300px;
      }
    }

    &--left {
      &--30 {
        padding-left: 30px;
      }
    }
  }

  &--top {
    &--10 {
      margin-top: 10px !important;
    }

    &--20 {
      margin-top: 20px !important;
    }

    &--30 {
      margin-top: 30px !important;
    }

    &--m30 {
      margin-top: -30px !important;
    }

    &--60 {
      margin-top: 40px;

      @include media-breakpoint-up(lg) {
        margin-top: 60px;
      }
    }

    &--80 {
      margin-top: 40px;

      @include media-breakpoint-up(md) {
        margin-top: 60px;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 80px;
      }
    }
  }

  &--bottom {
    &--20 {
      margin-bottom: 20px;
    }

    &--30 {
      margin-bottom: 30px !important;
    }

    &--m30 {
      margin-bottom: -30px !important;
    }

    &--40 {
      margin-bottom: 40px !important;
    }

    &--m40 {
      margin-bottom: -40px !important;
    }

    &--50 {
      margin-bottom: 30px !important;

      @include media-breakpoint-up(md) {
        margin-bottom: 50px !important;
      }
    }

    &--60 {
      margin-bottom: 40px !important;

      @include media-breakpoint-up(lg) {
        margin-bottom: 60px !important;
      }
    }

    &--80 {
      margin-bottom: 40px !important;

      @include media-breakpoint-up(md) {
        margin-bottom: 60px !important;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 80px !important;
      }
    }

    &--120 {
      margin-bottom: 60px !important;

      @include media-breakpoint-up(md) {
        margin-bottom: 80px !important;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 120px !important;
      }
    }
  }
}
