/*=============================================
=            Default CSS            =
=============================================*/
@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700&display=swap&subset=cyrillic');

*, *::after, *::before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font-size: 15px;
  visibility: visible;
  position: relative;

  &.no-overflow {
    overflow: hidden;
  }
}

ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

a {
  transition: all 0.3s ease;

  &:hover {
    text-decoration: none;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 15px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='black'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 95% 50%;
}

.slick-slide > div > div {
  vertical-align: middle;
}

.ht-swiper-button {
  &-nav {
    position: absolute;
    cursor: pointer;
    transition: all .3s cubic-bezier(.645, .045, .355, 1), visibility .3s linear 2s, opacity .3s linear 2s;
    background-image: none;
    text-align: center;
    user-select: none;
    outline: none !important;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 500;
    color: $gray-900;
    border: 2px solid rgba(34, 34, 34, .16);
    border-radius: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, .01);
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background: none;

    &:hover {
      background-color: $secondary;
      border-color: $secondary;
      color: $white;
    }
  }

  &-prev {
    left: 0;
  }

  &-next {
    right: 0;
  }
}

.swiper-pagination {
  position: static;
  display: block;
  max-width: 100%;
  text-align: center;
  width: auto;
  z-index: 7;
  line-height: 1;

  &-bullet {
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin: 0 5px;

    &-active {
      background-color: $gray-900;
      cursor: default;
    }
  }

  .swiper-pagination-bullet {
    vertical-align: bottom;
  }
}

input {
  &::placeholder {
    transition: $transition-base;
  }
}

.btn-primary {
  box-shadow: 0 10px 20px rgba(65, 58, 164, 0.2);
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
