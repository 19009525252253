.see-more-link {
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
  color: #393939;

  &:hover {
    color: $secondary;
    text-decoration: underline;
  }
}
